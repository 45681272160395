// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-expertises-aerospace-js": () => import("./../../../src/pages/expertises/aerospace.js" /* webpackChunkName: "component---src-pages-expertises-aerospace-js" */),
  "component---src-pages-expertises-animal-js": () => import("./../../../src/pages/expertises/animal.js" /* webpackChunkName: "component---src-pages-expertises-animal-js" */),
  "component---src-pages-expertises-automotive-js": () => import("./../../../src/pages/expertises/automotive.js" /* webpackChunkName: "component---src-pages-expertises-automotive-js" */),
  "component---src-pages-expertises-classic-vehicles-js": () => import("./../../../src/pages/expertises/classic-vehicles.js" /* webpackChunkName: "component---src-pages-expertises-classic-vehicles-js" */),
  "component---src-pages-expertises-construction-js": () => import("./../../../src/pages/expertises/construction.js" /* webpackChunkName: "component---src-pages-expertises-construction-js" */),
  "component---src-pages-expertises-defence-security-js": () => import("./../../../src/pages/expertises/defence-security.js" /* webpackChunkName: "component---src-pages-expertises-defence-security-js" */),
  "component---src-pages-expertises-event-art-js": () => import("./../../../src/pages/expertises/event-art.js" /* webpackChunkName: "component---src-pages-expertises-event-art-js" */),
  "component---src-pages-expertises-luxury-js": () => import("./../../../src/pages/expertises/luxury.js" /* webpackChunkName: "component---src-pages-expertises-luxury-js" */),
  "component---src-pages-expertises-manufacturing-js": () => import("./../../../src/pages/expertises/manufacturing.js" /* webpackChunkName: "component---src-pages-expertises-manufacturing-js" */),
  "component---src-pages-expertises-shipbuilding-js": () => import("./../../../src/pages/expertises/shipbuilding.js" /* webpackChunkName: "component---src-pages-expertises-shipbuilding-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-policy-cookies-js": () => import("./../../../src/pages/policy/cookies.js" /* webpackChunkName: "component---src-pages-policy-cookies-js" */),
  "component---src-pages-policy-privacy-js": () => import("./../../../src/pages/policy/privacy.js" /* webpackChunkName: "component---src-pages-policy-privacy-js" */),
  "component---src-pages-solutions-customs-js": () => import("./../../../src/pages/solutions/customs.js" /* webpackChunkName: "component---src-pages-solutions-customs-js" */),
  "component---src-pages-solutions-freight-js": () => import("./../../../src/pages/solutions/freight.js" /* webpackChunkName: "component---src-pages-solutions-freight-js" */),
  "component---src-pages-solutions-tracking-js": () => import("./../../../src/pages/solutions/tracking.js" /* webpackChunkName: "component---src-pages-solutions-tracking-js" */),
  "component---src-pages-special-freight-js": () => import("./../../../src/pages/special-freight.js" /* webpackChunkName: "component---src-pages-special-freight-js" */)
}

